<template>
    <tr class="usage-total__table-row usage-total__table-row_body">
        <td class="usage-total__table-cell usage-total__table-cell_body">
            <span class="usage-total__table-value">{{compareDaterange | daterangeText}}</span>
        </td>
        <td class="usage-total__table-cell usage-total__table-cell_body usage-total__table-cell_tar">
            <span class="usage-total__table-value">
                <template v-if="!compareUserMethod">нет</template>
                <template v-else>{{compareUserMethod.methodCount | intFormat}}</template>
            </span>
        </td>
    </tr>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";

    export default {
        name: "CompareCompareRow",
        props: {
            compareDaterange: {
                type: Object,
                required: true
            },
            userMethod: {
                type: Object,
                required: true
            },
            compareUsersMethods: {
                type: Array,
                required: true
            },
        },
        computed: {
            compareUserMethod() {
                return this.compareUsersMethods?.find(({ userId, methodId }) => userId === this.userMethod.userId && methodId === this.userMethod.methodId);
            },
        },
        filters: {
            daterangeText
        },
    }
</script>
