<template>
    <div class="usage-total">
        <section class="usage-total__settings">
            <h2 class="sr-only">Настройки отчета "Использование"</h2>
            <form class="usage-total__settings" @submit.prevent>
                <div class="usage-total__dateranges">
                    <daterange-picker class="usage-total__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                        :minDate="minDate"
                        :maxDate="maxDate"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="usage-total__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                        :minDate="minDate"
                        :maxDate="maxDate"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="usage-total__toggles">
                    <toggle-button class="usage-total__toggle-input"
                        :color="toggleColors"
                        v-model="modeModel"
                        :width="130"
                        :labels="modeToggleLabels"
                    />
                </div>
                <div class="usage-total__selects">
                    <select class="usage-total__select"
                        v-model="usersFilterModel"
                    >
                        <option class="usage-total__option" value="all">Все пользователи</option>
                        <option class="usage-total__option"
                            v-for="user in users.byId"
                            :key="user.id"
                            :value="user.id"
                        >
                            {{user.name}}
                        </option>
                    </select>
                    <select class="usage-total__select"
                        v-model="methodsFilterModel"
                    >
                        <option class="usage-total__option" value="all">Все отчеты</option>
                        <option class="usage-total__option"
                            v-for="method in methods"
                            :key="method.id"
                            :value="method.id"
                        >
                            {{method.title}}
                        </option>
                    </select>
                </div>
            </form>
        </section>
        <spinner class="usage-total__spinner" v-if="status === 'loading'" />
        <error-message class="usage-total__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Использование".
        </error-message>
        <section class="usage-total__data" v-if="status === 'success'">
            <h2 class="sr-only">Данные отчета "Использование"</h2>
            <p class="usage-total__text" v-if="reportUsersMethods.length === 0">
                Нет действий, удовлетворяющих выбранным критериям.
            </p>
            <template v-else>
                <div class="usage-total__base" v-if="modeId === 'base'">
                    <div class="usage-total__box box">
                        <table class="usage-total__table usage-total__table_striped usage-total__table_hover">
                            <colgroup>
                                <col class="usage-total__table-column usage-total__table-column_base-title">
                                <col class="usage-total__table-column usage-total__table-column_base-report">
                                <col class="usage-total__table-column usage-total__table-column_count">
                            </colgroup>
                            <thead>
                                <tr class="usage-total__table-row usage-total__table-row_head">
                                    <th class="usage-total__table-cell usage-total__table-cell_head usage-total__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('userName')"
                                    >
                                        <div class="usage-total__table-cell-content">
                                            <span class="usage-total__table-value">Пользователь</span>
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'userName' && sorting.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'userName' && sorting.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </div>
                                    </th>
                                    <th class="usage-total__table-cell usage-total__table-cell_head usage-total__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('methodTitle')"
                                    >
                                        <div class="usage-total__table-cell-content">
                                            <span class="usage-total__table-value">Отчет</span>
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'methodTitle' && sorting.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'methodTitle' && sorting.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </div>
                                    </th>
                                    <th class="usage-total__table-cell usage-total__table-cell_head usage-total__table-cell_tar usage-total__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('methodCount')"
                                    >
                                        <div class="usage-total__table-cell-content">
                                            <span class="usage-total__table-value">Число просмотров</span>
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'methodCount' && sorting.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="usage-total__table-value usage-total__table-value_icon"
                                                v-if="sorting.field === 'methodCount' && sorting.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="usage-total__table-row usage-total__table-row_body"
                                    v-for="userMethod in reportUsersMethods"
                                    :key="`${userMethod.userId}-${userMethod.methodId}`"
                                >
                                    <td class="usage-total__table-cell usage-total__table-cell_body usage-total__table-cell_title">
                                        <router-link class="link link_red"
                                            :to="{ name: 'UsageUser', params: { id: userMethod.userId } }"
                                            :title="`Смотреть использование ${userMethod.userName}`"
                                        >
                                            {{userMethod.userName}}
                                        </router-link>
                                    </td>
                                    <td class="usage-total__table-cell usage-total__table-cell_body">
                                        <span class="usage-total__table-value">{{userMethod.methodTitle}}</span>
                                    </td>
                                    <td class="usage-total__table-cell usage-total__table-cell_body usage-total__table-cell_tar">
                                        <span class="usage-total__table-value">{{userMethod.methodCount}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="usage-total__compare-other-period" v-else-if="modeId === 'compareOtherPeriod'">
                    <p class="usage-total__text" v-if="!compareUsage">Выберите период сравнения.</p>
                    <template v-else>
                        <div class="usage-total__box box"
                            v-for="userMethod in reportUsersMethods"
                            :key="`${userMethod.userId}-${userMethod.methodId}`"
                        >
                            <table class="usage-total__table usage-total__table_striped usage-total__table_hover">
                                <caption class="usage-total__table-caption usage-total__table-caption_compare">
                                    <router-link class="link link_red"
                                        :to="{ name: 'UsageUser', params: { id: userMethod.userId } }"
                                        :title="`Смотреть использование ${userMethod.userName}`"
                                    >
                                        {{userMethod.userName}}
                                    </router-link> — {{userMethod.methodTitle}}
                                </caption>
                                <colgroup>
                                    <col class="usage-total__table-column usage-total__table-column_compare-title">
                                    <col class="usage-total__table-column usage-total__table-column_count">
                                </colgroup>
                                <thead>
                                    <tr class="usage-total__table-row usage-total__table-row_head">
                                        <th class="usage-total__table-cell usage-total__table-cell_head" scope="col">
                                            <span class="usage-total__table-value">Период</span>
                                        </th>
                                        <th class="usage-total__table-cell usage-total__table-cell_head usage-total__table-cell_tar" scope="col">
                                            <span class="usage-total__table-value">Число просмотров</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <compare-primary-row
                                        :userMethod="userMethod"
                                        :reportDaterange="reportDaterange"
                                        :compareUsersMethods="compareUsersMethods"
                                    />
                                    <compare-compare-row
                                        :userMethod="userMethod"
                                        :compareDaterange="compareDaterange"
                                        :compareUsersMethods="compareUsersMethods"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </template>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import DaterangePicker from "@/components/DaterangePicker";
    import CompareReportRow from "./usage-total/CompareReportRow";
    import CompareCompareRow from "./usage-total/CompareCompareRow";

    export default{
        name: "UsageTotal",
        components: {
            DaterangePicker,
            CompareReportRow,
            CompareCompareRow,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                type: Object,
                required: true
            },
            minDate: {
                type: Date,
                required: true
            },
            maxDate: {
                type: Date,
                required: true
            },
            users: {
                type: Object,
                required: true
            },
            methods: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                modeId: state => state.usage.modeId,
                usersFilter: state => state.usage.filters.total.users,
                methodsFilter: state => state.usage.filters.total.methods,
                sorting: state => state.usage.sortings.total,
                data: state => state.usage.data.users
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setUsageReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(daterange) {
                    this.setUsageCompareDaterange(daterange);
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setUsageParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            usersFilterModel: {
                get() {
                    return this.usersFilter;
                },
                set(value) {
                    this.setFilter({ filter: "users", value });
                }
            },
            methodsFilterModel: {
                get() {
                    return this.methodsFilter;
                },
                set(value) {
                    this.setFilter({ filter: "methods", value });
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredUsages() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportUsage() {
                return this.data?.[daterangeKey(this.reportDaterange)];
            },
            reportUsersMethods() {
                return this.reportUsage?.map(
                    this.usageItemToUserMethod
                )?.filter(
                    this.dropUnknown
                )?.filter(
                    this.filterUser
                )?.filter(
                    this.filterMethod
                )?.sort((userMethod1, userMethod2) => {
                    let compare = this.sorting.field === "methodCount" ? compareNumbers : compareStrings;

                    return compare(
                        userMethod1?.[this.sorting.field],
                        userMethod2?.[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            compareUsage() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[daterangeKey(this.compareDaterange)];
                }
            },
            compareUsersMethods() {
                return this.compareUsage?.map(this.usageItemToUserMethod)?.filter(this.dropUnknown)?.filter(this.filterUser)?.filter(this.filterMethod);
            },
        },
        methods: {
            ...mapMutations([
                "setUsageReportDaterange",
                "setUsageCompareDaterange",
                "setUsageParameter",
                "setUsageFilter",
                "setUsageSorting",
            ]),
            ...mapActions([
                "requestUsages"
            ]),
            setFilter(filterParams) {
                this.setUsageFilter({ ...filterParams, table: "total" });
            },
            setSorting(field) {
                this.setUsageSorting({ table: "total", field });
            },
            usageItemToUserMethod(usageItem) {
                let user = this.users?.byId?.[usageItem.user_id];
                let method = this.methods?.find(method => {
                    let [url_level1, url_level2] = usageItem.url.split("/");

                    return method.url === `${url_level1}/${url_level2}`;
                });

                return {
                    userId: user?.id,
                    userName: user?.name,
                    methodId: method?.id,
                    methodTitle: method?.title,
                    methodCount: usageItem.count,
                }
            },
            dropUnknown({ userId, methodId }) {
                return userId && methodId;
            },
            filterUser(userMethod) {
                if (this.usersFilter === "all") {
                    return true;
                }

                return userMethod.userId === this.usersFilter;
            },
            filterMethod(userMethod) {
                if (this.methodsFilter === "all") {
                    return true;
                }

                return userMethod.methodId === this.methodsFilter;
            },
            showReport() {
                this.status = "success";
            },
            loadUsages() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestUsages({
                    dateranges: daterangesWithoutData,
                    type: "users"
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.requiredUsages.every(Boolean)) {
                this.showReport();
            } else {
                this.loadUsages();
            }
        },
        watch: {
            requiredUsages(requiredUsages) {
                if (!requiredUsages.every(Boolean)) {
                    this.loadUsages();
                }
            }
        }
    }
</script>

<style lang="scss">
    .usage-total__settings {
        margin-bottom: 15px;
    }
    .usage-total__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-total__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-total__selects {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        margin-top: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .usage-total__select {
        min-width: 240px;
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
    .usage-total__base {
        display: grid;
        place-items: start;
    }
    .usage-total__compare-other-period {
        display: grid;
        place-items: start;
        grid-gap: 15px;
    }
    .usage-total__box {
        padding: 16px;
        overflow-x: auto;

        &_chart {
            padding: 16px;
        }
    }
    .usage-total__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;

        &_compare {
            width: 800px;
        }
    }
    .usage-total__table-column {
        &_base-title {
            width: 300px;
        }

        &_base-report {
            width: 430px;
        }

        &_count {
            width: 200px;
        }
    }
    .usage-total__table-row {
        &_body {
            .usage-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .usage-total__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .usage-total__table-cell {
        font-size: 20px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_baseline {
            vertical-align: baseline;
        }

        &_small {
            font-size: 16px;
        }

        &_tac {
            text-align: center;
        }

        &_tar {
            text-align: right;
        }
    }
    .usage-total__table-cell-content {
        display: inline-flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .usage-total__table-value {
        display: block;
        line-height: 1;

        &_icon {
            width: 18px;
            height: 18px;
            margin-left: 3px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
</style>
