<template>
    <tr class="usage-total__table-row usage-total__table-row_body">
        <td class="usage-total__table-cell usage-total__table-cell_body">
            <span class="usage-total__table-value">{{reportDaterange | daterangeText}}</span>
        </td>
        <td class="usage-total__table-cell usage-total__table-cell_body usage-total__table-cell_tar">
            <span class="usage-total__table-value">{{userMethod.methodCount | intFormat}}</span>
            <difference class="usage-total__table-value usage-total__table-value_diff"
                v-if="compareUserMethod"
                :primaryValue="userMethod.methodCount"
                :compareValue="compareUserMethod.methodCount"
                mode="subtraction"
                filter="intFormat"
            />
        </td>
    </tr>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareReportRow",
        components: {
            Difference,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            userMethod: {
                type: Object,
                required: true
            },
            compareUsersMethods: {
                type: Array,
                required: true
            },
        },
        computed: {
            compareUserMethod() {
                return this.compareUsersMethods?.find(({ userId, methodId }) => userId === this.userMethod.userId && methodId === this.userMethod.methodId);
            },
        },
        filters: {
            daterangeText
        },
    }
</script>
